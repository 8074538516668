import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );
  };

  return (
    <form ref={form} onSubmit={sendEmail} className="bg-gray-900 p-8 rounded-lg max-w-sm md:max-w-4xl mx-auto text-black">
  <h1 className="text-white font-bold text-center text-[20px] py-6">Get Your Quote Now</h1>
  <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0 mb-4">
    <input
      type="text"
      name="user_name"
      placeholder="Your Name"
      className="w-full md:w-1/3 p-2 rounded border border-red-500 focus:outline-none focus:border-blue-500"
      required
    />
    <input
      type="email"
      name="user_email"
      placeholder="Email"
      className="w-full md:w-1/3 p-2 rounded border border-red-500 focus:outline-none focus:border-blue-500"
      required
    />
    <input
      type="text"
      name="user_phone"
      placeholder="Phone"
      className="w-full md:w-1/3 p-2 rounded border border-red-500 focus:outline-none focus:border-blue-500"
      required
    />
  </div>
  <textarea
    name="message"
    placeholder="Message"
    className="w-full p-2 mb-4 rounded border border-red-500 focus:outline-none focus:border-blue-500"
    required
  />
  <input
    type="submit"
    value="Submit"
    className="bg-white text-blue-900 py-2 px-4 rounded cursor-pointer hover:bg-gray-200 transition"
  />
</form>

  );
};
