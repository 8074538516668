import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const phoneNumber = "+91-7571008899";
  const handleCallClick = () => {
    window.location.href = "tel:" + phoneNumber;
  };
  return (
    <nav className="bg-slate-800 w-full p-4 flex flex-col justify-between items-start my-6 h-auto md:h-52">
      <div className="flex flex-col md:flex-row items-center w-full">
        <div className="flex flex-col items-center md:items-start w-full md:w-auto">
          <img
            src="/assets/brand-logo.png"
            alt="ServoGuard Pvt. Ltd."
            className="h-20 w-auto m-0 p-0"
          />
          <div className="flex flex-col items-start text-white text-lg ml-0 md:ml-4 mt-2 md:mt-0">
            <p className="text-sm md:text-[10px] m-0">
              
            </p>
          </div>
        </div>
        <p className="text-white text-sm md:text-md bg-slate-700 rounded-md p-4 mx-0 mt-4 md:mt-14 md:mx-3 w-full md:w-auto text-center">
          We deliver top-tier power conditioning solutions like High-capacity
          Transformers and Servo Stabilizers through a trusted network of
          partners, ensuring excellence both in India and abroad. Our commitment
          extends to supporting your renewable energy goals with innovative,
          cost-effective solar power solutions.
        </p>
      </div>

      {/* Navbar Description */}
      <div className="w-full flex flex-col md:flex-row justify-between mt-6">
        <div className="flex flex-col md:flex-row">
          {/* left side panel of navbar description */}
          <div className="flex flex-col items-center md:items-start md:flex-row mt-2 md:mt-12">
            <div className="flex gap-2 items-center">
              <div className="flex flex-col p-2">
                <p className="text-[14px] text-[#FCF8F3] md:text-[black]">Helpline</p>
                <p className="text-[14px] text-[#FCF8F3] md:text-[black]">Number</p>
              </div>
              <p className="text-2xl  text-[#ff0f0f] hover:underline hover:underline-offset-2">
                <a className="p-3" href={"tel:" + phoneNumber} onClick={handleCallClick}>
                  {phoneNumber}
                </a>
              </p>
            </div>
            <div className="flex items-center justify-center gap-1 text-[18px] px-6 text-[#FCF8F3]  md:text-slate-700 p-2">
              <p>support@servoguards.com</p>
            </div>
          </div>

          <div className="w-full h-auto mt-4 md:mt-0">
        <ul className="flex flex-wrap justify-center align-middle bg-slate-700 rounded-xl p-1">
          <li className="mx-4 text-[15px] text-[#FCF8F3]">Home</li>
          <div className="border-r border-[#f8f8f8] h-6"></div>
          <li className="mx-4 text-[15px] text-[#FCF8F3]">Know Us</li>
          <div className="border-r border-[#f8f8f8] h-6"></div>
          <li className="mx-4 text-[15px] text-[#FCF8F3]">What We Offer</li>
          <div className="border-r border-[#f8f8f8] h-6"></div>
          <li className="mx-4 text-[15px] text-[#FCF8F3]">Contact Us</li>
          <div className="border-r border-[#f8f8f8] h-6"></div>
          <li className="mx-4 text-[15px] text-[#FCF8F3]">Register Complaint</li>
        </ul>
      </div>

          
        </div>

        <div className="flex mx-0 md:mx-8 my-2 h-9 p-2 items-center gap-1 bg-gray-300  mt-14">
            <p className="mx-2 text-[15px]">Our Client</p>
            <div className="border-r border-[#ff0f0f] h-6"></div>
            <p className="mx-2 text-[15px]">Careers</p>
            <div className="border-r border-[#ff0f0f] h-6"></div>
            <p className="mx-2 text-[15px]">Blog</p>
            <div className="border-r border-[#ff0f0f] h-6"></div>
            <p className="mx-2 text-[15px]">Register Complaint</p>
          </div>
      </div>

      

      
    </nav>
  );
};

export default Navbar;
