import Main from "./components/Main";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import KnowUs from "./components/Pages/KnowUs";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Main />,
    },
    {
      path: "/know-us",
      element: <KnowUs />,
    },
    // Add additional routes here for other pages
  ]);

  return (
    <>
      {/* bottom right whatsapp logo and email */}
      <div className="fixed z-50 bottom-0 right-0 mb-4 mr-4">
        <a
          href="https://wa.me/7571008899"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="h-[50px] w-[50px] my-2 shadow-lg rounded-full"
            src="/assets/whatsapp.png"
            alt="whatsapp logo"
          />
        </a>
        <a href="mailto:support@servoguards.com">
          <img
            className="h-[50px] w-[50px] my-2 shadow-lg rounded-full"
            src="/assets/gmail.png"
            alt="gmail logo"
          />
        </a>
      </div>
      <Navbar />
      <RouterProvider router={router} />
      <Footer />
    </>
  );
}

export default App;
