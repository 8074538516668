import React from 'react'
import { ContactUs } from '../ContactUs'
import Carousel from '../Carousel'
import Navbar from '../Navbar'
import Footer from '../Footer';

const slides = ["assets/1.jpg", "assets/2.jpg", "assets/3.jpg"];

const Main = () => {
  return (
    <>
    <div className="bg-[#EBF4F6]">
      

      {/* Navbar Component
      <Navbar /> */}

      {/* carousel Component */}
      <div className=" w-full h-[150px] lg:h-[577px] px-6 mt-3 lg:mt-40">
        <Carousel autoSlide={true} autoSlideInterval={3000}>
          {slides.map((s, index) => (
            <img
              key={index}
              src={s}
              className="max-w-full max-h-full object-contain"
              alt="Banner Images.">
            </img>
          ))}
        </Carousel>
      </div>

      {/* Main body of the website */}
      <div className="my-6">
      <div className="my-1 bg-[#F5F7F8] p-4">
        <ContactUs />
      </div>

      <div className="flex justify-center px-4 py-3">
        <h1 className="text-[24px] md:text-[36px] font-bold text-center">
          India's Best Servo Voltage{" "}
          <span className="text-[#ff0f0f]">Stabilizer Manufacturer</span>
        </h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 md:mx-40 mx-10 my-4 gap-4">
        <div className="flex justify-center">
          <img
            className="h-auto max-w-full md:h-[500px] md:w-[500px]"
            src="assets/bannerimage.png"
            alt="Banner Image"
          />
        </div>

        <div>
          <h1 className="text-[24px] md:text-[36px] font-semibold">
            OVERVIEW <span className="text-[#ff0f0f]">COMPANY</span>
          </h1>
          <p className="text-gray-600 text-[12px] md:text-[14px]">
            Servoguard, a premier brand of voltage stabilizers, adheres to
            rigorous quality standards to ensure zero defects in our products,
            processes, and services. Our competitive pricing maintains quality
            integrity, earning your trust. We are steadfast in our commitment to
            unwavering service. SERVOGUARD Power Tech, a manufacturer based in
            Uttar Pradesh, was founded in 2018. Since then, we’ve expanded our
            market presence in Uttar Pradesh and Bihar.
            <br />
            <br />
            Our wide range of products with expertise in serving major
            Stabilizer and Air-Conditioning brands, Servoguard has emerged as a
            leader in the industry. Our product range includes Servo voltage
            stabilizers (Oil Cooled, Air Cooled) for various industrial
            equipment, air conditioners, televisions, refrigerators, washing
            machines, treadmills, and a variety of home appliances. Committed to
            exceeding expectations, we continually strive for excellence and aim
            to become the market leader. At Servoguard, we prioritize a healthy
            work-life balance and foster both personal and professional growth.
            Our team consists of seasoned professionals and fresh talent across
            diverse fields, fostering innovative thinking and practices. Our
            state-of-the-art R&D Test Lab is equipped with calibrated testing
            equipment to ensure our stabilizers meet relevant ISO Standards.
          </p>
        </div>
      </div>

      <div className="container mx-auto p-5 px-10">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="flex flex-col p-4 px-6 py-10 hover:bg-[#EEEDEB] rounded-2xl">
            <h1 className="pt-6 pb-4 text-[18px] font-semibold text-[#FF0F0F]">
              COMMITMENT
            </h1>
            <hr />
            <p className="mt-5 text-[14px]">
              Servoguard's journey, rooted in the solid legacy of R.D. Electric
              Works, exemplifies unwavering growth and a steadfast commitment to
              superior quality.
            </p>
          </div>
          <div className="flex flex-col p-4 px-6 py-10 hover:bg-[#EEEDEB] rounded-2xl">
            <h1 className="pt-6 pb-4 text-[18px] font-semibold text-[#FF0F0F]">
              VISION
            </h1>
            <hr />
            <p className="mt-5 text-[14px]">
              At Servoguard, we are driven by a clear vision, to be at the
              forefront of innovation in the power control & conditioning
              industry.
            </p>
          </div>
          <div className="flex flex-col p-4 px-6 py-10 hover:bg-[#EEEDEB] rounded-2xl">
            <h1 className="pt-6 pb-4 text-[18px] font-semibold text-[#FF0F0F]">
              MISSION
            </h1>
            <hr />
            <p className="mt-5 text-[14px]">
              Our mission is simple: to provide our customers with the best
              possible experience under the Servoguard brand.
            </p>
          </div>
        </div>
      </div>

      <div>
        <h1 className="text-[#ff0f0f] text-[20px] md:text-[26px] font-semibold flex justify-center text-center px-4">
          SORVOGUARD INDIA PRODUCTS OF DIFFERENT SECTORS
        </h1>
        <div className="flex flex-wrap justify-center gap-4 py-5">
          <div className="flex flex-col items-center justify-center">
            <img
              className="w-[150px] md:w-[250px] h-auto rounded-full shadow-2xl transition-transform transform hover:scale-110"
              src="assets/industrial-4.jpg"
              alt="sector images"
            />
            <h1 className="text-[#ff0f0f] my-5 text-[16px] md:text-[22px] text-center">
              Industrial Sector
            </h1>
          </div>
          <div className="flex flex-col items-center justify-center">
            <img
              className="w-[150px] md:w-[250px] h-auto rounded-full shadow-2xl transition-transform transform hover:scale-110"
              src="assets/domestic-4.jpg"
              alt="sector images"
            />
            <h1 className="text-[#ff0f0f] my-5 text-[16px] md:text-[22px] text-center">
              Domestic Sector
            </h1>
          </div>
          <div className="flex flex-col items-center justify-center">
            <img
              className="w-[150px] md:w-[250px] h-auto rounded-full shadow-2xl transition-transform transform hover:scale-110"
              src="assets/commercial-4.jpg"
              alt="sector images"
            />
            <h1 className="text-[#ff0f0f] my-5 text-[16px] md:text-[22px] text-center">
              Commercial Sector
            </h1>
          </div>
          <div className="flex flex-col items-center justify-center">
            <img
              className="w-[150px] md:w-[250px] h-auto rounded-full shadow-2xl transition-transform transform hover:scale-110"
              src="assets/govt-o-4.jpg"
              alt="sector images"
            />
            <h1 className="text-[#ff0f0f] my-5 text-[16px] md:text-[22px] text-center">
              Government Sector
            </h1>
          </div>
        </div>
      </div>
    </div>
      

      
      
    </div>
    
    </>
  )
}

export default Main