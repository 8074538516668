// src/Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="flex flex-col lg:flex-row justify-between p-10 bg-gray-900 text-white">
      <div className="w-full lg:w-1/4 px-4 mb-8 lg:mb-0">
        <h3 className="text-lg font-bold border-b-2 border-red-700 pb-2 mb-4">ABOUT US</h3>
        <p className="text-sm leading-relaxed">
          SERVOGUARD POWER TECH is overseen by a team of engineers boasting over 15 years of expertise in power conditioning equipment. Our products are proudly manufactured in Uttar Pradesh under the SERVOGUARD brand.
        </p>
        <div className="flex mt-4 space-x-4">
          <a href="#" className="text-xl hover:text-red-700"><i className="fab fa-facebook-f"></i></a>
          <a href="#" className="text-xl hover:text-red-700"><i className="fab fa-pinterest"></i></a>
          <a href="#" className="text-xl hover:text-red-700"><i className="fab fa-instagram"></i></a>
        </div>
      </div>
      <div className="w-full lg:w-1/4 px-4 mb-8 lg:mb-0">
        <h3 className="text-lg font-bold border-b-2 border-red-700 pb-2 mb-4">QUICK LINKS</h3>
        <ul className="space-y-2">
          <li><a href="#" className="text-sm hover:text-red-700">Home</a></li>
          <li><a href="#" className="text-sm hover:text-red-700">About Us</a></li>
          <li><a href="#" className="text-sm hover:text-red-700">Our Products</a></li>
          <li><a href="#" className="text-sm hover:text-red-700">Our Clients</a></li>
          <li><a href="#" className="text-sm hover:text-red-700">Blog</a></li>
          <li><a href="#" className="text-sm hover:text-red-700">Contact Us</a></li>
          <li><a href="#" className="text-sm hover:text-red-700">Market Area</a></li>
        </ul>
      </div>
      <div className="w-full lg:w-1/4 px-4 mb-8 lg:mb-0">
        <h3 className="text-lg font-bold border-b-2 border-red-700 pb-2 mb-4">CONTACTS</h3>
        <p className="text-sm leading-relaxed">
    <br />
          Phone: +91-7571008899<br />
          Email: support@servoguards.com
        </p>
      </div>
      <div className="w-full lg:w-1/4 px-4">
        <h3 className="text-lg font-bold border-b-2 border-red-700 pb-2 mb-4">MARKET AREA</h3>
        <ul className="space-y-2">
          <li><a href="#" className="text-sm hover:text-red-700">Servo Voltage Stabilizer in Delhi</a></li>
          <li><a href="#" className="text-sm hover:text-red-700">Servo Voltage Stabilizer in Ghaziabad</a></li>
          <li><a href="#" className="text-sm hover:text-red-700">Servo Voltage Stabilizer in Surat</a></li>
          <li><a href="#" className="text-sm hover:text-red-700">Servo Voltage Stabilizer in Bangalore</a></li>
          <li><a href="#" className="text-sm hover:text-red-700">Servo Voltage Stabilizer in Lucknow</a></li>
          <li><a href="#" className="text-sm hover:text-red-700">Servo Voltage Stabilizer in Chennai</a></li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
